import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const DownloadIcon: FunctionComponent<
  { fill?: string } & SvgIconProps
> = ({ fill, ...props }) => {
  return (
    <SvgIcon {...props} width="14" height="15" viewBox="0 0 14 15" fill="none">
      <path
        d="M7.65625 1.40625C7.65625 1.05078 7.35547 0.75 7 0.75C6.61719 0.75 6.34375 1.05078 6.34375 1.40625V8.81641L3.74609 6.21875C3.47266 5.94531 3.0625 5.94531 2.81641 6.21875C2.54297 6.46484 2.54297 6.875 2.81641 7.12109L6.53516 10.8398C6.78125 11.1133 7.19141 11.1133 7.4375 10.8398L11.1836 7.12109C11.4297 6.875 11.4297 6.46484 11.1836 6.21875C10.9102 5.94531 10.5 5.94531 10.2539 6.21875L7.65625 8.81641V1.40625ZM3.5 9.0625H1.75C0.765625 9.0625 0 9.85547 0 10.8125V13C0 13.9844 0.765625 14.75 1.75 14.75H12.25C13.207 14.75 14 13.9844 14 13V10.8125C14 9.85547 13.207 9.0625 12.25 9.0625H10.4727L9.16016 10.375H12.25C12.4688 10.375 12.6875 10.5938 12.6875 10.8125V13C12.6875 13.2461 12.4688 13.4375 12.25 13.4375H1.75C1.50391 13.4375 1.3125 13.2461 1.3125 13V10.8125C1.3125 10.5938 1.50391 10.375 1.75 10.375H4.8125L3.5 9.0625ZM11.8125 11.9062C11.8125 11.5508 11.5117 11.25 11.1562 11.25C10.7734 11.25 10.5 11.5508 10.5 11.9062C10.5 12.2891 10.7734 12.5625 11.1562 12.5625C11.5117 12.5625 11.8125 12.2891 11.8125 11.9062Z"
        fill={fill ?? "#B7E2FA"}
      />
    </SvgIcon>
  );
};
