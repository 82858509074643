import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const TelescopeIcon: FunctionComponent<
  { fill?: string } & SvgIconProps
> = ({ fill, ...props }) => {
  return (
    <SvgIcon {...props} width="17" height="15" viewBox="0 0 17 15" fill="none">
      <path
        d="M12.8281 7.09375L14.5234 6.13672L12.3359 2.33594L10.6406 3.32031L12.8281 7.09375ZM9.24609 3.48438C9.08203 3.07422 9.21875 2.60938 9.60156 2.39062L12.0625 0.96875C12.4727 0.75 13.0195 0.886719 13.2383 1.29688L15.8633 5.83594C16.1094 6.27344 15.9727 6.79297 15.5625 7.03906L13.1016 8.46094C12.6914 8.67969 12.1719 8.54297 11.9258 8.13281L11.8984 8.10547L10.75 8.73438C10.75 8.84375 10.7773 8.95312 10.7773 9.08984C10.7773 9.63672 10.5586 10.1289 10.2305 10.5117L11.5977 13.8477C11.7344 14.2031 11.5703 14.5859 11.2422 14.7227C10.8867 14.8594 10.5039 14.6953 10.3672 14.3672L9.08203 11.1953C8.91797 11.25 8.75391 11.25 8.5625 11.25C8.39844 11.25 8.23438 11.25 8.07031 11.1953L6.78516 14.3672C6.64844 14.6953 6.26562 14.8594 5.91016 14.7227C5.58203 14.5859 5.41797 14.2031 5.55469 13.8477L6.75781 10.8945L4.35156 12.1797C3.94141 12.4258 3.42188 12.2617 3.17578 11.8516L3.14844 11.8242L1.94531 12.5352C1.64453 12.6992 1.23438 12.5898 1.04297 12.2891L0.359375 11.0586C0.167969 10.7305 0.277344 10.3477 0.578125 10.1562L1.80859 9.47266L1.69922 9.28125C1.45312 8.87109 1.58984 8.35156 1.97266 8.10547L9.24609 3.48438ZM11.2422 6.98438L9.90234 4.63281L3.03906 8.98047L4.10547 10.8398L6.45703 9.58203C6.40234 9.41797 6.40234 9.25391 6.40234 9.0625C6.40234 7.85938 7.35938 6.875 8.58984 6.875C9.19141 6.875 9.76562 7.14844 10.1484 7.55859L11.2422 6.98438ZM8.58984 9.9375C9.05469 9.9375 9.46484 9.55469 9.46484 9.0625C9.46484 8.59766 9.05469 8.1875 8.58984 8.1875C8.09766 8.1875 7.71484 8.59766 7.71484 9.0625C7.71484 9.55469 8.09766 9.9375 8.58984 9.9375Z"
        fill={fill ?? "#3A2084"}
      />
    </SvgIcon>
  );
};
