import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const ServerIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="32" height="28" viewBox="0 0 32 28" fill="none">
      <path
        d="M4 3C3.4375 3 3 3.5 3 4V8C3 8.5625 3.4375 9 4 9H28C28.5 9 29 8.5625 29 8V4C29 3.5 28.5 3 28 3H4ZM0 4C0 1.8125 1.75 0 4 0H28C30.1875 0 32 1.8125 32 4V8C32 10.25 30.1875 12 28 12H4C1.75 12 0 10.25 0 8V4ZM4 19C3.4375 19 3 19.5 3 20V24C3 24.5625 3.4375 25 4 25H28C28.5 25 29 24.5625 29 24V20C29 19.5 28.5 19 28 19H4ZM0 20C0 17.8125 1.75 16 4 16H28C30.1875 16 32 17.8125 32 20V24C32 26.25 30.1875 28 28 28H4C1.75 28 0 26.25 0 24V20ZM24.5 22C24.5 21.1875 25.125 20.5 26 20.5C26.8125 20.5 27.5 21.1875 27.5 22C27.5 22.875 26.8125 23.5 26 23.5C25.125 23.5 24.5 22.875 24.5 22ZM26 4.5C26.8125 4.5 27.5 5.1875 27.5 6C27.5 6.875 26.8125 7.5 26 7.5C25.125 7.5 24.5 6.875 24.5 6C24.5 5.1875 25.125 4.5 26 4.5ZM20.5 22C20.5 21.1875 21.125 20.5 22 20.5C22.8125 20.5 23.5 21.1875 23.5 22C23.5 22.875 22.8125 23.5 22 23.5C21.125 23.5 20.5 22.875 20.5 22ZM22 4.5C22.8125 4.5 23.5 5.1875 23.5 6C23.5 6.875 22.8125 7.5 22 7.5C21.125 7.5 20.5 6.875 20.5 6C20.5 5.1875 21.125 4.5 22 4.5Z"
        fill="#2C81E4"
      />
    </SvgIcon>
  );
};
