'use strict';

let wasm;

const cachedTextDecoder = (typeof TextDecoder !== 'undefined' ? new TextDecoder('utf-8', { ignoreBOM: true, fatal: true }) : { decode: () => { throw Error('TextDecoder not available') } } );

if (typeof TextDecoder !== 'undefined') { cachedTextDecoder.decode(); }
let cachedUint8ArrayMemory0 = null;

function getUint8ArrayMemory0() {
    if (cachedUint8ArrayMemory0 === null || cachedUint8ArrayMemory0.byteLength === 0) {
        cachedUint8ArrayMemory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8ArrayMemory0;
}

function getStringFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return cachedTextDecoder.decode(getUint8ArrayMemory0().subarray(ptr, ptr + len));
}

const heap = new Array(128).fill(undefined);

heap.push(undefined, null, true, false);

let heap_next = heap.length;

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

function getObject(idx) { return heap[idx]; }

let WASM_VECTOR_LEN = 0;

const cachedTextEncoder = (typeof TextEncoder !== 'undefined' ? new TextEncoder('utf-8') : { encode: () => { throw Error('TextEncoder not available') } } );

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length, 1) >>> 0;
        getUint8ArrayMemory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len, 1) >>> 0;

    const mem = getUint8ArrayMemory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3, 1) >>> 0;
        const view = getUint8ArrayMemory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
        ptr = realloc(ptr, len, offset, 1) >>> 0;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

let cachedDataViewMemory0 = null;

function getDataViewMemory0() {
    if (cachedDataViewMemory0 === null || cachedDataViewMemory0.buffer.detached === true || (cachedDataViewMemory0.buffer.detached === undefined && cachedDataViewMemory0.buffer !== wasm.memory.buffer)) {
        cachedDataViewMemory0 = new DataView(wasm.memory.buffer);
    }
    return cachedDataViewMemory0;
}

let stack_pointer = 128;

function addBorrowedObject(obj) {
    if (stack_pointer == 1) throw new Error('out of js stack');
    heap[--stack_pointer] = obj;
    return stack_pointer;
}

function dropObject(idx) {
    if (idx < 132) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}
/**
 * @param {any} data_type_obj
 * @returns {any}
 */
function validateDataType(data_type_obj) {
    try {
        const ret = wasm.validateDataType(addBorrowedObject(data_type_obj));
        return takeObject(ret);
    } finally {
        heap[stack_pointer++] = undefined;
    }
}

/**
 * @param {any} entity_type_obj
 * @returns {any}
 */
function validateEntityType(entity_type_obj) {
    try {
        const ret = wasm.validateEntityType(addBorrowedObject(entity_type_obj));
        return takeObject(ret);
    } finally {
        heap[stack_pointer++] = undefined;
    }
}

/**
 * @param {any} property_type_obj
 * @returns {any}
 */
function validatePropertyType(property_type_obj) {
    try {
        const ret = wasm.validatePropertyType(addBorrowedObject(property_type_obj));
        return takeObject(ret);
    } finally {
        heap[stack_pointer++] = undefined;
    }
}

async function __wbg_load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);

            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn("`WebAssembly.instantiateStreaming` failed because your server does not serve Wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n", e);

                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);

    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };

        } else {
            return instance;
        }
    }
}

function __wbg_get_imports() {
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbindgen_json_parse = function(arg0, arg1) {
        const ret = JSON.parse(getStringFromWasm0(arg0, arg1));
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_json_serialize = function(arg0, arg1) {
        const obj = getObject(arg1);
        const ret = JSON.stringify(obj === undefined ? null : obj);
        const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbindgen_throw = function(arg0, arg1) {
        throw new Error(getStringFromWasm0(arg0, arg1));
    };

    return imports;
}

function __wbg_finalize_init(instance, module) {
    wasm = instance.exports;
    __wbg_init.__wbindgen_wasm_module = module;
    cachedDataViewMemory0 = null;
    cachedUint8ArrayMemory0 = null;



    return wasm;
}

function initSync(module) {
    if (wasm !== undefined) return wasm;


    if (typeof module !== 'undefined') {
        if (Object.getPrototypeOf(module) === Object.prototype) {
            ({module} = module);
        } else {
            console.warn('using deprecated parameters for `initSync()`; pass a single object instead');
        }
    }

    const imports = __wbg_get_imports();

    if (!(module instanceof WebAssembly.Module)) {
        module = new WebAssembly.Module(module);
    }

    const instance = new WebAssembly.Instance(module, imports);

    return __wbg_finalize_init(instance, module);
}

async function __wbg_init(module_or_path) {
    if (wasm !== undefined) return wasm;


    if (typeof module_or_path !== 'undefined') {
        if (Object.getPrototypeOf(module_or_path) === Object.prototype) {
            ({module_or_path} = module_or_path);
        } else {
            console.warn('using deprecated parameters for the initialization function; pass a single object instead');
        }
    }

    if (typeof module_or_path === 'undefined') {
        module_or_path = new URL('type-system_bg.wasm', "");
    }
    const imports = __wbg_get_imports();

    if (typeof module_or_path === 'string' || (typeof Request === 'function' && module_or_path instanceof Request) || (typeof URL === 'function' && module_or_path instanceof URL)) {
        module_or_path = fetch(module_or_path);
    }

    const { instance, module } = await __wbg_load(await module_or_path, imports);

    return __wbg_finalize_init(instance, module);
}

let initialized = undefined;
class TypeSystemInitializer {
    constructor() { }
}
/**
 * Initializes the package. There is a one time global setup fee (sub 30ms), but subsequent
 * requests to initialize will be instantaneous, so it's not imperative to reuse the same parser.
 */
TypeSystemInitializer.initialize = async (options) => {
    if (initialized === undefined) {
        const loadModule = options ?? (undefined);
        initialized = __wbg_init(loadModule).then(() => undefined);
    }
    await initialized;
    return new TypeSystemInitializer();
};

const DATA_TYPE_META_SCHEMA = "https://blockprotocol.org/types/modules/graph/0.3/schema/data-type";

const ENTITY_TYPE_META_SCHEMA = "https://blockprotocol.org/types/modules/graph/0.3/schema/entity-type";
/**
 * Returns all the IDs of all types referenced in a given property type.
 *
 * @param {EntityType} entityType
 */
const getReferencedIdsFromEntityType = (entityType) => {
    const constrainsPropertiesOnPropertyTypes = new Set();
    const constrainsLinksOnEntityTypes = new Set();
    const constrainsLinkDestinationsOnEntityTypes = new Set();
    const inheritsFromEntityTypes = [];
    for (const propertyDefinition of Object.values(entityType.properties)) {
        if ("items" in propertyDefinition) {
            constrainsPropertiesOnPropertyTypes.add(propertyDefinition.items.$ref);
        }
        else {
            constrainsPropertiesOnPropertyTypes.add(propertyDefinition.$ref);
        }
    }
    for (const inheritedEntityType of entityType.allOf ?? []) {
        inheritsFromEntityTypes.push(inheritedEntityType.$ref);
    }
    for (const [linkTypeId, linkDefinition] of Object.entries(entityType.links ?? {})) {
        /** @todo - if we had the `typedEntries` helper here we wouldn't need this cast */
        constrainsLinksOnEntityTypes.add(linkTypeId);
        if (linkDefinition.items.oneOf !== undefined) {
            linkDefinition.items.oneOf
                .map((oneOfEntry) => oneOfEntry.$ref)
                .forEach((ele) => constrainsLinkDestinationsOnEntityTypes.add(ele));
        }
    }
    return {
        constrainsPropertiesOnPropertyTypes: [
            ...constrainsPropertiesOnPropertyTypes,
        ],
        constrainsLinksOnEntityTypes: [...constrainsLinksOnEntityTypes],
        constrainsLinkDestinationsOnEntityTypes: [
            ...constrainsLinkDestinationsOnEntityTypes,
        ],
        inheritsFromEntityTypes: [...inheritsFromEntityTypes],
    };
};

const PROPERTY_TYPE_META_SCHEMA = "https://blockprotocol.org/types/modules/graph/0.3/schema/property-type";
/**
 * Type guard to determine if a given {@link PropertyValues} is an array definition.
 *
 * @param {PropertyValues} propertyValues
 */
const isPropertyValuesArray = (propertyValues) => "type" in propertyValues && propertyValues.type === "array";
/**
 * Returns all the IDs of all types referenced in a given property type.
 *
 * @param {PropertyType} propertyType
 */
const getReferencedIdsFromPropertyType = (propertyType) => {
    const recurseOneOf = (oneOf) => {
        const constrainsValuesOnDataTypes = new Set();
        const constrainsPropertiesOnPropertyTypes = new Set();
        for (const oneOfValue of oneOf) {
            if (isPropertyValuesArray(oneOfValue)) {
                const nestedIds = recurseOneOf(oneOfValue.items.oneOf);
                nestedIds.constrainsPropertiesOnPropertyTypes.forEach((ele) => constrainsPropertiesOnPropertyTypes.add(ele));
                nestedIds.constrainsValuesOnDataTypes.forEach((ele) => constrainsValuesOnDataTypes.add(ele));
            }
            else if ("properties" in oneOfValue) {
                // property type object definition
                for (const propertyDefinition of Object.values(oneOfValue.properties)) {
                    if ("items" in propertyDefinition) {
                        constrainsPropertiesOnPropertyTypes.add(propertyDefinition.items.$ref);
                    }
                    else {
                        constrainsPropertiesOnPropertyTypes.add(propertyDefinition.$ref);
                    }
                }
            }
            else {
                // data type reference
                constrainsValuesOnDataTypes.add(oneOfValue.$ref);
            }
        }
        return { constrainsValuesOnDataTypes, constrainsPropertiesOnPropertyTypes };
    };
    const { constrainsValuesOnDataTypes, constrainsPropertiesOnPropertyTypes } = recurseOneOf(propertyType.oneOf);
    return {
        constrainsValuesOnDataTypes: [...constrainsValuesOnDataTypes],
        constrainsPropertiesOnPropertyTypes: [
            ...constrainsPropertiesOnPropertyTypes,
        ],
    };
};

/**
 * Checks if a given URL string is a valid base URL.
 *
 * @param {BaseUrl} url - The URL string.
 * @returns {(Result<BaseUrl, ParseBaseUrlError>)} - an Ok with an inner of the string as a
 * BaseUrl if valid, or an Err with an inner ParseBaseUrlError
 */
const validateBaseUrl = (url) => {
    if (url.length > 2048) {
        return {
            type: "Err",
            inner: { reason: "TooLong" },
        };
    }
    try {
        void new URL(url);
        if (url.endsWith("/")) {
            return {
                type: "Ok",
                inner: url,
            };
        }
        else {
            return {
                type: "Err",
                inner: { reason: "MissingTrailingSlash" },
            };
        }
    }
    catch (err) {
        return {
            type: "Err",
            inner: { reason: "UrlParseError", inner: JSON.stringify(err) },
        };
    }
};
const versionedUrlRegExp = /(.+\/)v\/(.*)/;
/**
 * Checks if a given URL string is a Block Protocol compliant Versioned URL.
 *
 * @param {string} url - The URL string.
 * @returns {(Result<VersionedUrl, ParseVersionedUrlError>)} - an Ok with an inner of the string
 as
 * a VersionedUrl if valid, or an Err with an inner ParseVersionedUrlError
 */
const validateVersionedUrl = (url) => {
    if (url.length > 2048) {
        return {
            type: "Err",
            inner: { reason: "TooLong" },
        };
    }
    const groups = versionedUrlRegExp.exec(url);
    if (groups === null) {
        return {
            type: "Err",
            inner: { reason: "IncorrectFormatting" },
        };
    }
    else {
        const [_match, baseUrl, version] = groups;
        if (!baseUrl) {
            return {
                type: "Err",
                inner: { reason: "IncorrectFormatting" },
            };
        }
        if (!version || version.length === 0) {
            return {
                type: "Err",
                inner: { reason: "MissingVersion" },
            };
        }
        const index = version.search(/[^0-9]/);
        if (index === 0) {
            return {
                type: "Err",
                inner: {
                    reason: "InvalidVersion",
                    inner: [version, "invalid digit found in string"],
                },
            };
        }
        else if (index > 0) {
            return {
                type: "Err",
                inner: {
                    reason: "AdditionalEndContent",
                    inner: version.substring(index),
                },
            };
        }
        const versionNumber = Number(version);
        if (versionNumber > 4294967295) {
            return {
                type: "Err",
                inner: {
                    reason: "InvalidVersion",
                    inner: [version, "number too large to fit in target type"],
                },
            };
        }
        const validBaseUrlResult = validateBaseUrl(baseUrl);
        if (validBaseUrlResult.type === "Err") {
            return {
                type: "Err",
                inner: { reason: "InvalidBaseUrl", inner: validBaseUrlResult.inner },
            };
        }
        return { type: "Ok", inner: url };
    }
};
/**
 * Extracts the base URL from a Versioned URL.
 *
 * @param {VersionedUrl} url - The versioned URL.
 * @throws if the versioned URL is invalid.
 */
const extractBaseUrl = (url) => {
    if (url.length > 2048) {
        throw new Error(`URL too long: ${url}`);
    }
    const groups = versionedUrlRegExp.exec(url);
    if (groups === null) {
        throw new Error(`Not a valid VersionedUrl: ${url}`);
    }
    const [_match, baseUrl, _version] = groups;
    if (baseUrl === undefined) {
        throw new Error(`Not a valid VersionedUrl: ${url}`);
    }
    return baseUrl;
};
/**
 * Extracts the version from a Versioned URL.
 *
 * @param {VersionedUrl} url - The versioned URL.
 * @throws if the versioned URL is invalid.
 */
const extractVersion = (url) => {
    if (url.length > 2048) {
        throw new Error(`URL too long: ${url}`);
    }
    const groups = versionedUrlRegExp.exec(url);
    if (groups === null) {
        throw new Error(`Not a valid VersionedUrl: ${url}`);
    }
    const [_match, _baseUrl, version] = groups;
    return Number(version);
};

exports.DATA_TYPE_META_SCHEMA = DATA_TYPE_META_SCHEMA;
exports.ENTITY_TYPE_META_SCHEMA = ENTITY_TYPE_META_SCHEMA;
exports.PROPERTY_TYPE_META_SCHEMA = PROPERTY_TYPE_META_SCHEMA;
exports.TypeSystemInitializer = TypeSystemInitializer;
exports.extractBaseUrl = extractBaseUrl;
exports.extractVersion = extractVersion;
exports.getReferencedIdsFromEntityType = getReferencedIdsFromEntityType;
exports.getReferencedIdsFromPropertyType = getReferencedIdsFromPropertyType;
exports.initSync = initSync;
exports.isPropertyValuesArray = isPropertyValuesArray;
exports.validateBaseUrl = validateBaseUrl;
exports.validateDataType = validateDataType;
exports.validateEntityType = validateEntityType;
exports.validatePropertyType = validatePropertyType;
exports.validateVersionedUrl = validateVersionedUrl;
