import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const MagicIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="16" height="15" viewBox="0 0 16 15" fill="none">
      <path
        d="M6.52344 1.92578L5.51172 2.30859C5.42969 2.33594 5.375 2.41797 5.375 2.5C5.375 2.60938 5.42969 2.69141 5.51172 2.71875L6.52344 3.10156L6.90625 4.11328C6.93359 4.19531 7.01562 4.25 7.125 4.25C7.20703 4.25 7.28906 4.19531 7.31641 4.11328L7.69922 3.10156L8.73828 2.71875C8.82031 2.69141 8.875 2.60938 8.875 2.5C8.875 2.41797 8.82031 2.33594 8.73828 2.30859L7.69922 1.92578L7.31641 0.886719C7.28906 0.804688 7.20703 0.75 7.125 0.75C7.01562 0.75 6.93359 0.804688 6.90625 0.886719L6.52344 1.92578ZM12.7305 2.0625L13.6875 3.01953L10.6797 6.02734L9.72266 5.07031L12.7305 2.0625ZM2.3125 12.5078L8.79297 6L9.75 6.95703L3.24219 13.4375L2.3125 12.5078ZM11.8008 1.16016L1.38281 11.5781C0.863281 12.0977 0.863281 12.918 1.38281 13.4375L2.3125 14.3672C2.83203 14.8867 3.65234 14.8867 4.17188 14.3672L14.5898 3.94922C15.1094 3.42969 15.1094 2.60938 14.5898 2.08984L13.6602 1.16016C13.1406 0.640625 12.3203 0.640625 11.8008 1.16016ZM0.316406 3.97656C0.207031 4.00391 0.125 4.14062 0.125 4.25C0.125 4.38672 0.207031 4.52344 0.316406 4.55078L1.875 5.125L2.44922 6.68359C2.47656 6.79297 2.61328 6.875 2.75 6.875C2.85938 6.875 2.99609 6.79297 3.02344 6.68359L3.625 5.125L5.15625 4.55078C5.29297 4.52344 5.375 4.38672 5.375 4.25C5.375 4.14062 5.29297 4.00391 5.15625 3.97656L3.625 3.375L3.02344 1.84375C2.99609 1.70703 2.85938 1.625 2.75 1.625C2.61328 1.625 2.47656 1.70703 2.44922 1.84375L1.875 3.375L0.316406 3.97656ZM9.94141 10.9766C9.83203 11.0039 9.75 11.1406 9.75 11.25C9.75 11.3867 9.83203 11.5234 9.94141 11.5508L11.5 12.125L12.0742 13.6836C12.1016 13.793 12.2383 13.875 12.375 13.875C12.4844 13.875 12.6211 13.793 12.6484 13.6836L13.25 12.125L14.7812 11.5508C14.918 11.5234 15 11.3867 15 11.25C15 11.1406 14.918 11.0039 14.7812 10.9766L13.25 10.375L12.6484 8.84375C12.6211 8.70703 12.4844 8.625 12.375 8.625C12.2383 8.625 12.1016 8.70703 12.0742 8.84375L11.5 10.375L9.94141 10.9766Z"
        fill="#3A2084"
      />
    </SvgIcon>
  );
};
